import React from "react";
import Link from "gatsby-link";
import "./news.scss";
import { graphql, StaticQuery } from "gatsby";
import DateTime from "luxon/src/datetime";
import NewsListItem from "@page-contents/index/news-list-item";
import defaultImage from "@img/ic-empty-news.png";
import ShowMore from "@components/show-more";

const News = ({ data = {} }) => {
	const { allMarkdownRemark: { edges = {} } = {} } = data;
	return (
		<section className="full-width news">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Новости</h2>
					</div>
				</div>
				<div className="row hidden-xs">
					<ShowMore>
						{edges &&
							edges.map(({ node }, index) => {
								const {
									frontmatter: { image, title, path, publishedAt } = {}
								} = node;
								const dt = DateTime.fromISO(publishedAt)
									.setLocale("ru")
									.toLocaleString(DateTime.DATE_MED);
								return (
									<div
										key={index}
										className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10"
									>
										<Link className="news__item" to={path}>
											<div className="news__item__img">
												<img
													alt="Изображение к новости"
													src={image || defaultImage}
												/>
											</div>
											<div className="news__item__content">
												<div className="news__item__content__block">
													<h4 className="news__item__content__block__title">
														{title}
													</h4>
													<div className="news__item__content__block__date">
														{dt}
													</div>
												</div>
												<div className="news__item__content__block">
													<div className="news__item__content__block__link">
														Читать
													</div>
												</div>
											</div>
										</Link>
									</div>
								);
							})}
					</ShowMore>
				</div>
				<div className="row hidden-md hidden-sm">
					<ShowMore buttonClassName="news__button-show-more">
						{edges &&
							edges.map(({ node }, index) => {
								const {
									frontmatter: { image, title, path, publishedAt } = {}
								} = node;
								return (
									<div className="col-xs-12 col-sm-6 col-md-3" key={index}>
										<NewsListItem
											pic={image}
											text={title}
											path={path}
											publishedAt={publishedAt}
											alt="Изображение"
											className="news-list__item"
										/>
									</div>
								);
							})}
					</ShowMore>
				</div>
			</div>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				allMarkdownRemark(
					filter: { frontmatter: { path: { regex: "/news/" } } }
					sort: { fields: frontmatter___publishedAt, order: DESC }
				) {
					edges {
						node {
							frontmatter {
								path
								image
								title
								publishedAt
								content
							}
						}
					}
				}
			}
		`}
		render={data => <News data={data} {...props} />}
	/>
);
